import { useEffect, type FC, useState } from "react";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { useQuery } from "react-query";
import axios from "axios";
import { useReduxDispatch, useReduxSelector } from "../../../hooks/useredux";
import { setcertificatemodal } from "../../../redux/ModalSlice";
import { useSelector } from "react-redux";
import { setActiveCourse, setSelectedCourse } from "../../../redux/courseSlice";
import { Button, Checkbox, Radio, notification, Form } from "antd";
import { useAuthUser } from "react-auth-kit";
import { useNavigate } from "react-router-dom";

// interface curry {
//   curriculum?: [];
//   _id?: string;
//   course_details: {
//     title?: string;
//     video_url?: string;
//   };
//   questions?: {
//     id: {
//       correctAnswer?: number;
//       point?: number;
//       question?: string;
//       questionType?: string;
//       answer?: [];
//     };
//   };
// }
interface general {
	_id?: string;
	image?: string;
	title?: string;
	updated_at?: string;
	created_at?: string;
	curriculum?: demo[];
}
interface demo {
	_id?: number;
	correctAnswer?: string;
	point?: string;
	question?: string;
	questionType?: string;
	answer?: demo2[];

	course_details?: {
		title?: string;
		video_url?: string;
	};
	questions?: {
		correctAnswer?: string;
		point?: string;
		question?: string;
		questionType?: string;
		answer?: demo2[];
	};
}
// interface demo1 {
//   data?: {
//     correctAnswer?: string;
//     point?: string;
//     question?: string;
//     questionType?: string;
//     answer?: demo2[];
//   };
// }
interface demo2 {
	data?: string;
}

const Startvideo: FC = () => {
	const navigate = useNavigate();
	const { activeCourse, selectedCourse } = useReduxSelector(
		(state) => state.course,
	);
	const dispatch = useReduxDispatch();
	const user: any = useAuthUser()();

	const { data } = useQuery(
		`/madaniyat-va-sanat/${selectedCourse?.id}`,
		() =>
			axios({
				url: "https://api.babm.uz/courses/madaniyat-va-sanat/1",
			}).then((res) => res.data.data),
		{ refetchOnWindowFocus: false },
	);

	const course = data?.curriculum[activeCourse?.index];

	// useEffect(() => {
	// 	const call = async () => {
	// 		try {
	// 			await axios({
	// 				url: "https://api.babm.uz/courses/mark-course",
	// 				method: "POST",
	// 				data: {
	// 					course_id: selectedCourse?.id,
	// 					user_id: user?.id,
	// 					lesson_id: course?.id,
	// 				},
	// 			});
	// 			notification.success({
	// 				message: "Kursni muvofaqqiyatli tugatdingiz",
	// 			});
	// 		} catch (error) {
	// 			notification.error({
	// 				message: "Xatolik yuzberdi",
	// 			});
	// 		}
	// 	};

	// 	if (course?.id) call();
	// }, [course]);

	const nextCourse = () => {
		if (data?.curriculum?.length - 1 > activeCourse?.index)
			dispatch(setActiveCourse({ index: activeCourse?.index + 1 }));
	};
	const preveiousCourse = () => {
		if (activeCourse?.index > 0)
			dispatch(setActiveCourse({ index: activeCourse?.index - 1 }));
	};

	const certificateDowload = () => {
		navigate("/");
		notification.success({
			message: "Kursni muvofaqqiyatli tugatdingiz",
		});
		window.open(
			`https://api.babm.uz/download/certificate?user_id=${user?.id}&course_id=${selectedCourse?.id}`,
			"_blank",
		);
	};

	return (
		<div className=" w-full m-auto mt-3">
			<div className="flex w-[96%] justify-between items-center font-semibold cursor-pointer">
				<h3 className="font-semibold cursor-pointer hover:text-[#46A358]">
					{course?.course_details?.title}
				</h3>
				<Button
					onClick={nextCourse}
					className="bg-[#1677FF]  text-white text-sm w-[96px] h-[36px] rounded-md flex justify-center items-center gap-2"
					disabled={!(data?.curriculum?.length - 1 > activeCourse?.index)}
				>
					Oldinga <ArrowRightOutlined />
				</Button>
			</div>

			<iframe
				className="mt-[50px]"
				width="100%"
				height="500"
				src={course?.course_details?.video_url}
				title="YouTube video player"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
			/>

			<div className=" mt-7 bg-[#F5F5F5]">
				<Form
					id={activeCourse?.index}
					onFinish={async (e) => {
						try {
							await axios({
								url: "https://api.babm.uz/courses/mark-course",
								method: "POST",
								data: {
									course_id: selectedCourse?.id,
									user_id: user?.id,
									lesson_id: course?.id,
								},
							});
						} catch (error) {}
						data?.curriculum?.length - 1 > activeCourse?.index
							? nextCourse()
							: certificateDowload();
					}}
				>
					{course?.questions?.map(({ question, answers }: any, idx: number) => {
						return (
							<div className="ml-[30px] mb-[30px]" key={idx}>
								<div>{question}</div>
								<Form.Item
									rules={[
										{
											required: true,
											message: "Iltimos testga javob bering!",
										},
									]}
									name={`${idx}-test-course-${activeCourse?.index}`}
								>
									<Radio.Group className="flex flex-col gap-[10px] ml-[30px]">
										{answers.map((variant: any, idx: number) => (
											<Radio key={idx} value={variant}>
												{variant}
											</Radio>
										))}
									</Radio.Group>
								</Form.Item>
							</div>
						);
					})}

					<div className="flex justify-end  mr-4 pb-6 font-semibold cursor-pointer">
						<Button
							onClick={preveiousCourse}
							disabled={activeCourse?.index === 0}
							className="bg-[#1677FF] text-white mr-2  text-sm  h-[36px] rounded-md flex justify-center items-center gap-2"
						>
							<ArrowLeftOutlined /> Oldingi darsga qaytish
						</Button>
						<Button
							htmlType="submit"
							className="bg-[#1677FF] text-white text-sm h-[36px] rounded-md flex justify-center items-center gap-2"
						>
							{data?.curriculum?.length - 1 > activeCourse?.index ? (
								<>
									Tekshirish va Oldinga <ArrowRightOutlined />
								</>
							) : (
								<>Tekshirish va sertifikatni yuklab olish</>
							)}
						</Button>
					</div>
				</Form>
			</div>
		</div>
	);
};

export default Startvideo;
