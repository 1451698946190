const hero_data = [
  {
    title: "ONLINE DARSLAR",
    button_text: "START",
    flower_url:
      "https://firebasestorage.googleapis.com/v0/b/aema-image-upload.appspot.com/o/greenshop%2Fimages%2Fflower1.png?alt=media&token=0b53d608-7264-4c54-b497-a9bf054fcd9d"
  },
  {
    title: "QIZIQARLI MA'LUMOTLAR",
    button_text: "LET'S START",
    flower_url:
      "https://firebasestorage.googleapis.com/v0/b/aema-image-upload.appspot.com/o/greenshop%2Fimages%2Fhero-flower-1.png?alt=media&token=74ea8d3d-06b5-41e7-bb12-7caaf3035a6d"
  },
  {
    title: "FOYDALI WEBSITE",
    button_text: "BEGIN",
    flower_url:
      "https://firebasestorage.googleapis.com/v0/b/aema-image-upload.appspot.com/o/greenshop%2Fimages%2Fhero-flower-2.png?alt=media&token=5b5addec-d344-4897-a983-95c9b10a1662"
  }
  // {
  //   image_url:
  //     "https://eduon-backend.uz/media/course/covers/135d2e81659548e7885ea2f81be23427.png",
  // },
];

export { hero_data };
